import { defineStore } from "pinia";
import { reactive } from "vue";
import { useCategoriesStore } from "~/store/categories";
import { Routes } from "~/utils/routes";
import { useStoresStore } from "~/store/stores";
import { SUGGESTS_LIMIT, SEARCH_PER_PAGE } from "~/constants/search";
import type { IProduct } from "~/components/VProduct/VProduct.types";
import type { IGoodsProduct } from "~/store/goods";
import { formatGoodsProduct, useGoodsStore } from "~/store/goods";
import type { IPromoProduct } from "~/store/promo";
import { formatPromoProduct, usePromoStore } from "~/store/promo";

type ISuggestsStatus = "initial" | "pending" | "success" | "error";
type ISearchStatus = "initial" | "pending" | "success" | "exhausted";

interface ISearchStatuses {
  suggests: ISuggestsStatus;
  searchPromo: ISearchStatus;
  searchGoods: ISearchStatus;
}

export type ISearchResultProduct = IProduct & {
  category?: string;
  productCode?: string;
  isPromo: boolean;

  url: string;
  code?: string;
  categoryName?: string;
  startDate?: string;
  endDate?: string;
  progressiveDiscount?: {
    count: number;
    value: number;
  }[];
  progressiveDiscountType?: string;
  discountType?: string;
  discountValue?: number;
  previewDiscountTitle?: string;
};

interface ISearchState {
  goodsSuggests: ISearchResultProduct[];
  promosSuggests: ISearchResultProduct[];
  goodsSearchResults: ISearchResultProduct[];
  goodsSearchCurPage: number;
  promosSearchResults: ISearchResultProduct[];
  promosSearchCurPage: number;
}

export const useSearchStore = defineStore("search", () => {
  const state = reactive<ISearchState>({
    goodsSuggests: [],
    promosSuggests: [],
    goodsSearchResults: [],
    goodsSearchCurPage: 0,
    promosSearchResults: [],
    promosSearchCurPage: 0,
  });

  const categoriesStore = useCategoriesStore();
  const storesStore = useStoresStore();
  const promoStore = usePromoStore();
  const goodsStore = useGoodsStore();

  const status = reactive<ISearchStatuses>({
    suggests: "initial",
    searchPromo: "initial",
    searchGoods: "initial",
  });

  const formatSearchPromoProduct = (
    product: IPromoProduct,
  ): ISearchResultProduct => {
    return {
      ...formatPromoProduct(product),
      isPromo: true,
      productCode: product.productCode,
      category: product.articleCategory,
      categoryName:
        product.categoryName ||
        categoriesStore.getCategoryById(
          String(product.articleCategory),
          "promo",
        )?.name,
      startDate: product.startDate,
      endDate: product.endDate,
      progressiveDiscount: product.progressiveDiscount,
      progressiveDiscountType: product.progressiveDiscountType,
      discountType: product.discountType,
      discountValue: product.discountValue,
      previewDiscountTitle: product.previewDiscountTitle,
      url: promoStore.makePromoProductUrl(product),
    };
  };

  const formatSearchGoodsProduct = (
    product: IGoodsProduct,
  ): ISearchResultProduct => {
    const category = categoriesStore.getCategoryById(
      String(product.categories[0]),
      "goods",
    );

    const { name: categoryName } = category || {};
    const url = `${Routes.Product}/${product.id}-${product.code}?shopCode=${storesStore.currentStore.code}`;

    return {
      ...formatGoodsProduct(product),
      isPromo: false,
      code: product.code,
      productCode: product.id,
      category: String(product.categories[0]),
      categoryName,
      url,
    };
  };

  const getSuggests = async (query: string, storeCode?: string) => {
    status.suggests = "pending";
    try {
      const [promo, goods] = await Promise.allSettled([
        promoStore.getPromoSearch({
          query,
          storeCode,
          limit: SUGGESTS_LIMIT,
        }),
        goodsStore.requestProducts({
          term: query,
          storeCode,
          pagination: { number: 1, size: SUGGESTS_LIMIT },
        }),
      ]);

      if (promo.status === "fulfilled") {
        state.promosSuggests =
          promo.value.data.value?.data.map(formatSearchPromoProduct) || [];
        status.suggests = "success";
      }

      if (goods.status === "fulfilled") {
        state.goodsSuggests =
          goods.value.data.value?.goods.map(formatSearchGoodsProduct) || [];
        status.suggests = "success";
      }

      if (promo.status !== "fulfilled" && goods.status !== "fulfilled") {
        clearSuggests(true);
        status.suggests = "error";
      }
    } catch {
      clearSuggests(true);
      status.suggests = "error";
    } finally {
      if (status.suggests === "pending") {
        status.suggests = "initial";
      }
    }
  };

  const getSearchResults = async (query: string) => {
    if (!query.length) return;
    await getPromoSearchResults(query);

    if (status.searchPromo === "exhausted") {
      await getGoodsSearchResults(query);
    }
  };

  const getPromoSearchResults = async (query: string) => {
    if (status.searchPromo === "exhausted") return;

    try {
      status.searchPromo = "pending";
      state.promosSearchCurPage += 1;
      const { data, error } = await promoStore.getPromoSearch({
        query,
        limit: SEARCH_PER_PAGE,
        offset: SEARCH_PER_PAGE * (state.promosSearchCurPage - 1),
      });

      if (data.value) {
        const promos = data.value.data.map(formatSearchPromoProduct) || [];
        if (promos.length) {
          status.searchPromo = "success";
          state.promosSearchResults = [...state.promosSearchResults, ...promos];
        }

        if (promos.length < SEARCH_PER_PAGE) {
          status.searchPromo = "exhausted";
        }
      }

      if (error.value) {
        clearPromosSearch();
      }
    } catch (e) {
      clearPromosSearch();
    }
  };

  const getGoodsSearchResults = async (query: string) => {
    if (status.searchGoods === "exhausted") return;
    try {
      status.searchGoods = "pending";
      state.goodsSearchCurPage += 1;

      const { data, error } = await goodsStore.requestProducts({
        term: query,
        pagination: {
          number: state.goodsSearchCurPage,
          size: SEARCH_PER_PAGE,
        },
      });

      if (data.value) {
        const goods = data.value.goods.map(formatSearchGoodsProduct) || [];
        if (goods.length) {
          status.searchGoods = "success";
          state.goodsSearchResults = [...state.goodsSearchResults, ...goods];
        }

        if (goods.length < SEARCH_PER_PAGE) {
          status.searchGoods = "exhausted";
        }
      }

      if (error.value) {
        clearGoodsSearch();
      }
    } catch (e) {
      clearGoodsSearch();
    }
  };

  const clearSuggests = (withError?: boolean) => {
    state.goodsSuggests = [];
    state.promosSuggests = [];
    status.suggests = withError ? "error" : "initial";
  };

  const clearSearch = (initial?: boolean) => {
    clearPromosSearch(initial);
    clearGoodsSearch(initial);
  };
  const clearPromosSearch = (initial?: boolean) => {
    state.promosSearchResults = [];
    state.promosSearchCurPage = 0;
    status.searchPromo = initial ? "initial" : "exhausted";
  };

  const clearGoodsSearch = (initial?: boolean) => {
    state.goodsSearchResults = [];
    state.goodsSearchCurPage = 0;
    status.searchGoods = initial ? "initial" : "exhausted";
  };

  return {
    state,
    status,
    getSuggests,
    getSearchResults,
    clearSuggests,
    clearSearch,
  };
});
